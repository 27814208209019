<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        <app-i18n code="category.title"></app-i18n>
      </h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <a
            class="btn bg-theme-31 text-white sm:w-auto pb-2 pt-2"
            href="javascript:;"
            data-toggle="modal"
            :data-target="'#' + modalId"
          >
            <RefreshCwIcon class="w-5 h-5 mr-2 ml-2" />
            {{ i18n('common.refresh') }}
          </a>
          <!-- <button
            id="tabulator-print"
            class="
              btn btn-outline-secondary
              dark:text-white
              w-1/2
              sm:w-auto
              mr-2
              ml-2
            "
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button> -->
          <!-- <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="
                dropdown-toggle
                btn btn-outline-secondary
                dark:text-white
                w-full
                sm:w-auto
              "
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <!-- <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div> -->
        <loading-spinner
          type="controller"
          :duration="1000"
          :size="40"
          color="#4469A8"
          :loading="loading"
        />
        <div ref="table" class="relative">
          <div class="overflow-x-auto">
            <!-- scrollbar-hidden -->
            <table
              ref="tableRef"
              id="categoryTable"
              class="table table-report sm:mt-2"
            >
              <thead>
                <tr>
                  <!-- <th class="whitespace-nowrap">#</th> -->
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>

              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  :data-index="index"
                >
                  <td class="text-center">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ row.id }}
                    </div>
                  </td>
                  <!-- <td class="text-center flex items-center justify-center">
                    <app-list-item-image
                      :value="row.image"
                    ></app-list-item-image>
                  </td> -->
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ row.name }}
                    </div>
                  </td>
                  <!-- <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ row.product_count }}
                    </div>
                  </td> -->
                  <td class="w-40">
                    <div
                      class="flex items-center justify-center whitespace-nowrap"
                      :class="{
                        'text-theme-24': !row.is_active,
                        'text-green-600 dark:text-theme-30': row.is_active
                      }"
                    >
                      <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                      {{
                        row.is_active
                          ? i18n('common.activated')
                          : i18n('common.disabled')
                      }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex justify-center items-center space-x-4">
                      <button
                        v-if="!row.is_active && hasPermissionToEdit"
                        @click="toggleCategoryStatus(row.id, true)"
                        class="flex justify-center items-center gap-2 p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md text-green dark:text-theme-30"
                        style="color: green"
                      >
                        <UnlockIcon class="w-4 h-4 mr-1 ml-1" />
                        {{ i18n('common.activate') }}
                      </button>
                      <button
                        v-if="row.is_active && hasPermissionToEdit"
                        @click="toggleCategoryStatus(row.id, false)"
                        class="flex justify-center items-center gap-2 p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md text-theme-24 dark:text-theme-24"
                      >
                        <LockIcon class="w-4 h-4 mr-1 ml-1" />
                        {{ i18n('common.disable') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.category')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            :isFirstPage="isFirstPage"
            :isLastPage="isLastPage"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <ActivateModal item="category" />
    <DisableModal item="category" />
    <refresh-modal :id="modalId" @action="doAddCategories" />
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import xlsx from 'xlsx'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import ActivateModal from '@/components/modals/activate-modal.vue'
import DisableModal from '@/components/modals/disable-modal.vue'
import refreshModal from '@/components/modals/refresh-modal.vue'
import { CategoryPermissions } from './category-permissions'

// import dayjs from 'dayjs'

export default defineComponent({
  components: {
    ActivateModal,
    DisableModal,
    'refresh-modal': refreshModal
  },
  setup() {
    const tableLimit = ref(10)
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)
    const selectedId = ref()

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      tabulator,
      selected,
      selectAll,
      selectedId,
      tableLimit,
      tableRef
    }
  },
  data() {
    return {
      timer: null,
      filterInput: [],
      modalId: 'refresh-category-modal',
      columns: [
        // {
        //   name: 'image',
        //   field: 'image',
        //   label: 'category.fields.image',
        //   align: 'center'
        //   // required: true,
        // },
        {
          name: 'id',
          field: 'id',
          label: 'category.fields.id',
          align: 'center'
          // required: true,
        },
        {
          name: 'name',
          field: 'name',
          label: 'category.fields.name',
          align: 'center'
          // required: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'category.fields.status',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'category/list/rows',
      currentPage: 'category/list/currentPage',
      isFirstPage: 'category/list/isFirstPage',
      isLastPage: 'category/list/isLastPage',
      listLoading: 'category/list/loading',
      pagination: 'category/list/pagination',
      destroyLoading: 'category/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    hasPermissionToEdit() {
      return new CategoryPermissions(this.currentUser).edit
    },
    filterFields() {
      return [
        {
          label: this.i18n('category.fields.name'),
          value: 'name',
          type: 'text'
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.categories')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.categories')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'category/list/doFetch',
      doAddCategories: 'category/list/doAddCategories',
      doFetchNextPage: 'category/list/doFetchNextPage',
      doFetchPreviousPage: 'category/list/doFetchPreviousPage',
      doToggleCategoryStatus: 'category/list/doToggleCategoryStatus',
      doDestroy: 'category/destroy/doDestroy',
      doChangePaginationCurrentPage:
        'category/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'category/list/doChangePaginationPageSize',
      doArrangeCategories: 'category/form/doArrangeCategories'
    }),
    async toggleCategoryStatus(id, enabled) {
      id = `${id}`
      await this.doToggleCategoryStatus({ id, enabled })
    },
    async doFilter(filter) {
      this.filterInput = []
      if (!filter) return
      if (filter[0].field === 'name') {
        filter[0].operator = 'like'
      }
      this.filterInput = filter
      // if (Array.isArray(filter)) {
      //   this.filterInput.push(...filter)
      // } else {
      //   this.filterInput.push(filter)
      // }
      // if (!this.filterInput.length) return
      await this.doFetchFirstPage()
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        filter: this.filterInput,
        pagination: {
          limit: 10,
          sortBy: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'catigoryTable', 'categories')
    },
    onDownloadPdf() {
      $h.downloadPdf('#categoryTable', 'categories', [1, 3])
    },
    onPrint() {
      print({
        printable: 'categoryTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 700,
        ignoreElements: ['ignore-1', 'ignore-2']
      })
    },
    showModal(modalName, id) {
      this.selectedId = id
      switch (modalName) {
        case 'delete':
          cash('#delete-modal-preview').modal('show')
          break
        case 'addImage':
          cash('#add-image-to-category-modal').modal('show')
          break
      }
    },
    handleDragStart(e) {
      e.dataTransfer.setData('text/plain', e.currentTarget.dataset.index)
    },
    handleDragEnter(e) {
      e.currentTarget.classList.add('hover')
    },
    handleDragLeave(e) {
      e.currentTarget.classList.remove('hover')
    },
    handleDragOver(e) {
      e.preventDefault()
    },
    handleDrop(e) {
      e.preventDefault()
      const itemIndex = e.dataTransfer.getData('text/plain')
      const droppedIndex = e.currentTarget.dataset.index
      Array.from(e.currentTarget.parentNode.children).map(tr => {
        tr.classList.remove('hover')
      })
      this.handleListChange(itemIndex, droppedIndex)
    },
    handleListChange(index, newIndex) {
      if (index == newIndex) return
      const oldIndex = parseInt(index, 10)
      const newPositionIndex = parseInt(newIndex, 10)
      const movedItem = this.rows[oldIndex]
      this.rows.splice(oldIndex, 1)
      this.rows.splice(newPositionIndex, 0, movedItem)
      this.doArrange()
    },
    async doArrange() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        const list = this.rows.map(row => row.id)
        this.doArrangeCategories(list)
        clearTimeout(this.timer)
      }, 2000)
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
