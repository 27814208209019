import PermissionsClass from '@/security/Permission-classes'
import Permissions from '@/security/permissions'

export class CategoryPermissions extends PermissionsClass {
  constructor(currentUser) {
    super(currentUser, {
      edit: Permissions.categoryEdit
    })
  }
}
